import placeholder from "../../assets/images/placeholder1.svg"
import { offerTypeOptions } from "../propertyForm/steps/firstStep"
import React from "react"
import { FormattedMessage } from "react-intl"

export const propStates = {
  pending: "darkGreyBg",
  rejected: "errorBg",
}

export function getStatus(property) {
  const states = {
    pending: {
      label: <FormattedMessage defaultMessage="Pending" />,
      description: (
        <FormattedMessage defaultMessage="We received your ad, our employees will review it, once it is approved it will go live" />
      ),
      className: "property-status --pending",
    },
    approved: {
      label: <FormattedMessage defaultMessage="Published" />,
      description: (
        <FormattedMessage defaultMessage="your ad is published and everone on our website can see it" />
      ),
      className: "property-status --approved",
    },
    rejected: {
      label: <FormattedMessage defaultMessage="Rejected" />,
      className: "property-status --approved",
      description: property?.rejectReason,
    },
  }
  return states[property?.status]
}

export function getNotificationData(notification, sender) {
  let data = null
  switch (notification) {
    case "follow_user":
      data = {
        title: "Follow",
        description:
          sender[0].toUpperCase() +
          sender.slice(1) +
          " " +
          "started following you.",
      }
      break
    default:
      data = {
        title: "Notification",
        description: "Notification",
      }
      break
  }
  return data
}

export const getPropImage = prop =>
  prop?.assets?.filter?.(a => a.type === "image")?.[0]?.url || placeholder

export const getPropOfferType = prop =>
  offerTypeOptions.find(({ value }) => value === prop?.offerType)?.label
