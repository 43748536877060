import React from 'react';

export const ShareIcon = ({className}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.5039 5.20356L28.5201 14.01C28.5822 14.0852 28.6621 14.2619 28.6621 14.5201C28.6621 14.7782 28.5822 14.9549 28.5201 15.0301L19.5039 23.8366C19.4849 23.7529 19.4723 23.6525 19.4723 23.537V18.5777V17.5777H18.4723C12.1856 17.5777 6.71398 21.2741 3.49006 26.7754C3.40883 26.9024 3.31926 26.9555 3.24024 26.98C3.14815 27.0086 3.03516 27.0077 2.9251 26.9694C2.81426 26.9309 2.74085 26.8669 2.7037 26.8101C2.67524 26.7666 2.64839 26.7008 2.66982 26.5802C3.08218 24.5144 3.62027 22.5137 4.53982 20.6789L4.54852 20.6615L4.55655 20.6438C7.08424 15.0692 12.3632 11.0116 18.4723 11.0116H19.4723V10.0116V5.50311C19.4723 5.38761 19.4849 5.28725 19.5039 5.20356Z"
        stroke="#616E86"
        strokeWidth="2"
      />
    </svg>
  )
}