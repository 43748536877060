import React, { useEffect, useRef, useState } from "react"
import CloseIcon from "../../assets/icons/closeIcon"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  InstapaperShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  InstapaperIcon,
} from "react-share"
import { useDispatch } from "react-redux"
import { closeGeneralModal } from "../../actions/settingsActions"
import { FormattedMessage } from "react-intl"
import { Grid } from "@material-ui/core"
import { AiOutlineInstagram } from "react-icons/ai"

const ShareModal = ({ pathname, title = "Share Property" }) => {
  const dispatch = useDispatch()

  const baseUrl =
    process.env.GATSBY_SITE_URL || "https://simsar-website.netlify.app"

  function openInNewTab(url) {
    window.open(url, "_blank").focus()
  }
  console.log(baseUrl)
  console.log(pathname)

  const closeModal = () => {
    dispatch(closeGeneralModal())
  }
  function App() {
    const ref = useRef()
    // State for our modal
    const [isModalOpen, setModalOpen] = useState(false)
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(ref, () => setModalOpen(false))
    return (
      <div>
        {isModalOpen ? (
          <div ref={ref}>
            👋 Hey, I'm a modal. Click anywhere outside of me to close.
          </div>
        ) : (
          <button onClick={() => setModalOpen(true)}>Open Modal</button>
        )}
      </div>
    )
  }
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    }, [ref, handler])
  }

  return (
    <div className=" p-4 whiteBg br8 share">
      <div className="w-100 d-flex justify-content-between align-items-start">
        <h1 className="text-decorated font-bold black mb-4 shareproperty">
          {title ? title : <FormattedMessage defaultMessage="Share Property" />}
        </h1>
        <button className="darkGrey" onClick={closeModal} style={{ width: 30 }}>
          <CloseIcon />
        </button>
      </div>
      <div className="px-5 " style={{ marginTop: "60px" }}>
        <p
          className="font-800 px12 grey uppercase"
          style={{ letterSpacing: 2.5 }}
        >
          <FormattedMessage defaultMessage="Share via" />
        </p>
        <Grid
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            rowGap: "50px",
          }}
        >
          <Grid
            xs={6}
            sm={6}
            lg={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <FacebookShareButton
              className="mx-1 primary"
              url={`${baseUrl}${pathname}`}
              hashtag="#Simsar"
            >
              <FacebookIcon
                iconFillColor="#3C3F58"
                bgStyle={{ fill: "none" }}
              />
            </FacebookShareButton>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            lg={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <LinkedinShareButton className="mx-1" url={`${baseUrl}${pathname}`}>
              <LinkedinIcon
                iconFillColor="#3C3F58"
                bgStyle={{ fill: "none" }}
              />
            </LinkedinShareButton>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            lg={3}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "64px",
            }}
          >
            <button className="mx-1">
              <AiOutlineInstagram
                onClick={() => openInNewTab("https://www.instagram.com")}
                bgStyle={{ fill: "none" }}
                style={{
                  display: "flex",
                  width: "38px",
                  height: "38px",
                  fill: "#3C3F58",
                }}
              />
            </button>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            lg={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <TwitterShareButton className="mx-1" url={`${baseUrl}${pathname}`}>
              <TwitterIcon iconFillColor="#3C3F58" bgStyle={{ fill: "none" }} />
            </TwitterShareButton>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ShareModal
