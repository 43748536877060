import React from "react"
import Spinner from "../../assets/icons/spinner"
import { FavoriteIcon } from "../../assets/images/propertiesImages/favoriteIcon"
import { ShareIcon } from "../../assets/images/propertiesImages/shareIcon"
import useProperty from "./useProperty"

const ActionsButtons = ({ property }) => {
  const { isFav, isFavLoading, toggleFavorite, shareProperty, user } =
    useProperty(property)

  const isOwner = user?.id === property?.user?.id

  const handleToggleFavClick = e => {
    e.stopPropagation()
    toggleFavorite()
  }

  const handleShareClick = e => {
    e.stopPropagation()
    shareProperty()
  }

  return (
    <div className="actionBtnsBox">
      {property?.status === "approved" && (
        <button className="actionBtnWidth" onClick={handleShareClick}>
          <ShareIcon className="actionBtnWidth" />
        </button>
      )}
      {!isOwner && (
        <button className="" onClick={handleToggleFavClick}>
          {isFavLoading && <Spinner />}
          {!isFavLoading && (
            <FavoriteIcon
              className="actionBtnWidth"
              color={isFav ? "#FC4B67" : ""}
            />
          )}
        </button>
      )}
    </div>
  )
}

export default ActionsButtons
