import React from "react"
import useProperty from "./useProperty"
import { propStates } from "./utils"
import { navigate } from "gatsby"
import { BedIcon } from "../../assets/images/propertiesImages/bedIcon.js"
import { BathIcon } from "../../assets/images/propertiesImages/bathIcon.js"
import { SizeIcon } from "../../assets/images/propertiesImages/sizeIcon.js"
import { numberWithCommas, truncate } from "../../utils"
import useMediaQuery from "../hooksComponents/useMediaQuery"
import ActionsButtons from "./actionsButtons"
import ArrowRight from "../../assets/icons/arrowRight"
import placeholder from "../../assets/images/placeholder1.svg"

const PropertyHorizCard = ({ property }) => {
  const { propImage, offerType, statusInfo } = useProperty(property)

  const isMobile = useMediaQuery("(max-width: 528px)")

  const maxTitleVisibleChars = isMobile ? 40 : 80

  const status = property?.status

  return (
    <div className="relative">
      {status && status !== "approved" && (
        <div className={`absolute __badge top-10 ${statusInfo?.className}`}>
          <span className="text-uppercase white font-bold px20">
            {statusInfo?.label}
          </span>
        </div>
      )}
      <div
        className="whiteBg br8 p-3 card-effect pointer"
        style={{ width: "100%", height: "98.5%", maxHeight: 232 }}
        role="button"
        onClick={() => navigate(`/app/property/${property?.id}`)}
      >
        <div className="row">
          <div className="col-5">
            <img
              src={
                property &&
                property.assets &&
                property.assets[0] &&
                property.assets[0].url
                  ? property.assets[0]?.url
                  : placeholder
              }
              alt="property"
              width="100%"
              height="200px"
              style={{
                minHeight: "200px",
                marginTop: "-4px",
                marginLeft: "-6px",
                objectFit: "cover",
              }}
              className="object-cover br8"
            />
          </div>
          <div className="col-7" style={{ marginTop: "-8px" }}>
            <div
              className=""
              style={{ paddingRight: "-0.5rem", }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-left font-bold px26 pt-1 flex-1 no-wrap">
                  {offerType}
                </div>
                <ActionsButtons property={property} />
              </div>

              <div
                className="darkGrey mapFinderItemDescription"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <span title={property?.title}>
                  {truncate(property?.title, maxTitleVisibleChars)}
                </span>
              </div>
              <div style={{ position: "absolute", bottom: 16, width: "47%" }}>
                <div
                  className="d-flex flex-wrap align-items-center w-100"
                  style={{
                    justifyContent: "flex-start",
                    gap: "30px",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  <span className="darkGrey px14 d-flex align-items-center fst-italic">
                    <BedIcon />
                    <span className="ms-2">{property?.bedRoomsCount}</span>
                  </span>
                  <span className="darkGrey px14 d-flex align-items-center fst-italic">
                    <BathIcon />
                    <span className="ms-2">{property?.bathsCount}</span>
                  </span>
                  <span className="darkGrey px14 d-flex align-items-center fst-italic">
                    <SizeIcon />
                    <span className="ms-2">{property?.size}m2</span>
                  </span>
                </div>

                <div
                  className="secondaryLightBg py-2 br8 secondary font-bold"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="px20 mx-2">
                    SP&nbsp;{numberWithCommas(property?.price)}
                  </span>
                  <div style={{ marginRight: "10px" }}>
                    <ArrowRight size="1.5rem" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyHorizCard
