import React from "react"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { setGeneralModal, toggleModal } from "../../actions/settingsActions"
import { error, success } from "../../utils"
import dataApi from "../../_api/dataApi"
import ShareModal from "../modals/shareModal"
import { getPropImage, getPropOfferType, getStatus } from "./utils"

export default function useProperty(property) {
  const [isFav, setIsFav] = useState(property?.isFavorite)
  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state?.user?.user)
  const statusInfo = getStatus(property)
  const dispatch = useDispatch()

  const openAuthModal = () => {
    dispatch(toggleModal({ open: true }))
  }

  const shareProperty = () => {
    dispatch(
      setGeneralModal({
        open: true,
        Component: ShareModal,
        modalClassName: "confirm-modal",
        componentProps: {
          pathname: `/app/property/${property.id}`,
        },
      })
    )
  }

  const toggleFavorite = () => {
    if (!user?.id) {
      openAuthModal()
      return
    }
    setLoading(true)
    dataApi
      .togglePropertyFavorite(property?.id)
      .then(() => {
        setIsFav(isFav ? false : true)
        success(
          isFav ? (
            <FormattedMessage defaultMessage="Removed from favorite list" />
          ) : (
            <FormattedMessage defaultMessage="Added to favorite list" />
          )
        )
      })
      .catch(() => error())
      .finally(() => setLoading(false))
  }

  const propImage = getPropImage(property)

  const offerType = (
    <FormattedMessage
      defaultMessage="{offerType}"
      values={{ offerType: getPropOfferType(property) }}
    />
  )

  return {
    isFav,
    isFavLoading: loading,
    toggleFavorite,
    propImage,
    offerType,
    user,
    shareProperty,
    statusInfo,
  }
}
