import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { HorizontalIcon } from "../assets/images/horizontal"
import { VerticalIcon } from "../assets/images/vertical"
import { setViewMode } from "../actions/settingsActions"

const ViewModeButtons = () => {
  const viewMode =
    useSelector(state => state?.settings?.viewMode) || "horizontal"

  const dispatch = useDispatch()

  const handleSetViewMode = (mode = "horizontal") => {
    dispatch(setViewMode(mode))
  }

  return (
    <div
      className="primary font-bold button-shadow p-2 br8 whiteBg d-lg-flex d-none align-items-center justify-content-between"
      style={{ height: "100%" }}
    >
      <button className="mx-2" onClick={() => handleSetViewMode("horizontal")}>
        <HorizontalIcon
          style={{ width: 24, height: 24 }}
          color={viewMode === "horizontal" && "#3D3B4A"}
        />
      </button>
      <button className="mx-2" onClick={() => handleSetViewMode("vertical")}>
        <VerticalIcon
          style={{ width: 24, height: 24 }}
          color={viewMode === "vertical" && "#3D3B4A"}
        />
      </button>
    </div>
  )
}

export default ViewModeButtons
