import { SET_PROPERTIES } from "./types"
import { setLoading } from "./settingsActions"
import dataApi from "../_api/dataApi"
import { error } from "../utils"

export const getProperties =
  ({ filters, sort, page } = {}) =>
  dispatch => {
    dispatch(setLoading(true))
    return dataApi
      .getProperties({ filters, sort, page })
      .then(res => {
        if (res) {
          dispatch({ type: SET_PROPERTIES, payload: res })
        } else {
          error()
        }
      })
      .catch(() => {
        error()
      })
      .finally(() => dispatch(setLoading(false)))
  }
