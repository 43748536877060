import React, { useEffect } from "react"
import useProperty from "./useProperty"
import { propStates } from "./utils"
import { navigate } from "gatsby"
import { BedIcon } from "../../assets/images/propertiesImages/bedIcon.js"
import { BathIcon } from "../../assets/images/propertiesImages/bathIcon.js"
import { SizeIcon } from "../../assets/images/propertiesImages/sizeIcon.js"
import ImageComponent from "./imageComponent"
import ActionsButtons from "./actionsButtons"
import { numberWithCommas } from "../../utils"
import placeholder from "../../assets/images/placeholder1.svg"
import useMediaQuery from "../hooksComponents/useMediaQuery"

const PropertyVertCard = ({ property }) => {
  const { propImage, offerType, statusInfo } = useProperty(property)
  const isMobile = useMediaQuery("(max-width: 528px)")
  const isMD = useMediaQuery("(max-width: 1500px)")

  const status = property?.status

  return (
    <div className="relative">
      {status && status !== "approved" && (
        <div className={`absolute __badge top-10 ${statusInfo.className}`}>
          <span className="text-uppercase white font-bold px20">
            {statusInfo?.label}
          </span>
        </div>
      )}
      <div
        className="whiteBg br8 p-3 card-effect h-100 d-flex flex-column"
        onClick={() => navigate(`/app/property/${property?.id}`)}
        role="button"
      >
        <div className="relative overflow-hidden br8">
          <ImageComponent
            style={{ width: "100%", height: "100%" }}
            src={
              property &&
              property.assets &&
              property.assets[0] &&
              property.assets[0].url
                ? property.assets[0]?.url
                : placeholder
            }
            forcedHeight={isMobile ? "300px" : isMD ? "360px" : null}
            alt="property"
            className="pointer br8 relative"
          />
          <div
            className="secondaryLightBg py-2 br16 absolute"
            style={{ bottom: 10, left: 10 }}
          >
            <div className="font-bold secondary br16">
              <span className="px20 mx-2">
                SP&nbsp;{numberWithCommas(property?.price)}
              </span>
            </div>
          </div>
        </div>

        <div className="mx-lg-3 mx-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="font-bold px26 py-2">{offerType}</div>
            <ActionsButtons property={property} />
          </div>

          <div className="darkGrey px14 my-2 text-start">{property?.title}</div>
          <div
            className="d-flex flex-wrap align-items-center my-2 w-100"
            style={{
              justifyContent: "flex-start",
              gap: isMobile ? "10px" : isMD ? "15px" : "30px",
              flexWrap: "nowrap",
            }}
          >
            <span className="darkGrey px14 d-flex align-items-center fst-italic">
              <BedIcon />
              <span className="ms-2">{property?.bedRoomsCount}</span>
            </span>
            <span className="darkGrey px14 d-flex align-items-center fst-italic">
              <BathIcon />
              <span className="ms-2">{property?.bathsCount}</span>
            </span>
            <span className="darkGrey px14 d-flex align-items-center fst-italic">
              <SizeIcon />
              <span className="ms-2">{property?.size}m2</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyVertCard
