import React from 'react';

export const VerticalIcon = ({ color, style = {} }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect
        x="29.334"
        y="2.66602"
        width="26.6667"
        height="5.33333"
        rx="2"
        transform="rotate(90 29.334 2.66602)"
        fill={color ? color : "#BFC7D2"}
      />
      <rect
        x="18.666"
        y="2.66602"
        width="26.6667"
        height="5.33333"
        rx="2"
        transform="rotate(90 18.666 2.66602)"
        fill={color ? color : "#BFC7D2"}
      />
      <rect
        x="8"
        y="2.66602"
        width="26.6667"
        height="5.33333"
        rx="2"
        transform="rotate(90 8 2.66602)"
        fill={color ? color : "#BFC7D2"}
      />
    </svg>
  )
}