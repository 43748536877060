import React from "react"
import PropertyHorizCard from "./propertyHorizCard"
import PropertyVertCard from "./propertyVertCard"

const PropertyComponent = ({
  user,
  propertyData,
  mode = "vertical",
  ...props
}) => {
  return (
    <>
      {mode === "horizontal" ? (
        <PropertyHorizCard property={propertyData} />
      ) : (
        <PropertyVertCard property={propertyData} />
      )}
    </>
  )
}

export default PropertyComponent
