import React, { useLayoutEffect, useRef, useState } from "react"
import placeholder from "../../assets/images/placeholder1.svg"

const ImageComponent = ({ src, width, style = {}, className, forcedHeight, ...props }) => {
  const [imgHeight, setImgHeight] = useState("auto")

  const imgRef = useRef()
  useLayoutEffect(() => {
    const width = imgRef.current?.width
    if (width) {
      setImgHeight(width * 0.886)
    }
  }, [])
  return (
    <img
      {...props}
      ref={imgRef}
      style={{
        ...style,
        height: forcedHeight ? forcedHeight : imgHeight,
      }}
      src={src || placeholder}
      width={width || "100%"}
      className={`transition-all object-cover ${className}`}
    />
  )
}

export default ImageComponent
