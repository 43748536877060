import React from "react"
import { useSelector } from "react-redux"
import useMediaQuery from "./hooksComponents/useMediaQuery"
import PropertyComponent from "./propertyComponent"

const PropertyGrid = ({
  properties = [],
  minimized,
  forceVertical = false,
}) => {
  const viewMode =
    useSelector(state => state?.settings?.viewMode) || "horizontal"

  const isMedOrBelowScreen = useMediaQuery(`(max-width: 768px)`)

  const actualViewMode = forceVertical
    ? "vertical"
    : isMedOrBelowScreen
    ? "horizontal"
    : viewMode

  return (
    <div className="py-4">
      <div
        className={`properties-grid ${
          minimized
            ? "--minimized"
            : forceVertical
            ? "--vert"
            : actualViewMode === "horizontal"
            ? "--horizon"
            : "--vert"
        }`}
      >
        {properties?.map?.(property => (
          <PropertyComponent
            mode={actualViewMode}
            key={property.id}
            propertyData={property}
          />
        ))}
      </div>
    </div>
  )
}

export default PropertyGrid
