import React from 'react';

export const HorizontalIcon = ({ color, style = {} }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect
        x="3"
        y="5"
        width="26"
        height="8"
        rx="2"
        fill={color ? color : "#BFC7D2"}
      />
      <rect
        x="3"
        y="18"
        width="26"
        height="8"
        rx="2"
        fill={color ? color : "#BFC7D2"}
      />
      <rect
        x="17"
        y="5"
        width="12"
        height="8"
        rx="2"
        fill={color ? color : "#BFC7D2"}
      />
      <rect
        x="17"
        y="18"
        width="12"
        height="8"
        rx="2"
        fill={color ? color : "#BFC7D2"}
      />
    </svg>
  )
}